import React from "react"
import clsx from "clsx"

export const CardLink = props => {
  return (
    <a
      href={props.url}
      className="embed-card flex rounded-md border-2 border-gray-300 p-4 mx-9 text-base hover:opacity-60"
      target="_blank"
      rel="noreferrer"
    >
      {props.image && (
        <div className="embed-card-image w-1/6">
          <img src={props.image} alt={props.title} />
        </div>
      )}
      <div className={clsx(props.image && "w-5/6 pl-4")}>
        <h4 className="p-0 m-0 text-xl" style={{ paddingLeft: 0 }}>
          {props.title}
        </h4>
        {props.content && (
          <div className="overflow-hidden">{props.content}</div>
        )}
        {props.price && (
          <div className="text-red-500 text-2xl pt-2">{props.price}</div>
        )}
      </div>
    </a>
  )
}
