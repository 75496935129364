import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import rehypeReact from "rehype-react"
import { CardLink } from "../components/card-link"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "card-link": CardLink },
}).Compiler

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  if (post.frontmatter.draft) {
    return <>404</>
  }

  //const shareTitle = `${post.frontmatter.title} | ${siteTitle}`
  //const shareUrl = `https://blog.kksg.net/posts${pageContext.slug}`
  //const shareText = `${shareTitle} ${shareUrl}`

  // let htmlAst = post.htmlAst
  // htmlAst.children.forEach((el, index) => {
  //   if (
  //     el.children &&
  //     el.children[0] &&
  //     el.children[0].tagName === "card-link"
  //   ) {
  //     htmlAst.children[index].tagName = "div"
  //   }
  // })

  const thumbnailImage = post.frontmatter.thumbnail
    ? `${data.site.siteMetadata.siteUrl}${post.frontmatter.thumbnail.childImageSharp.fluid.src}`
    : post.frontmatter.image

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={thumbnailImage}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <div className="flex justify-center text-base font-bold text-gray-600 mt-4">
            <time dateTime={post.frontmatter.date}>
              {post.frontmatter.textDate}
            </time>
            <div className="before:content-['•'] before:px-2">
              {Math.floor(post.fields.readingTime.minutes)} min read
            </div>
          </div>
          <h1
            itemProp="headline"
            className="text-2xl mx-4 my-0 mt-4 text-center"
          >
            {post.frontmatter.title}
          </h1>
        </header>
        <section itemProp="articleBody" className="article-body">
          {renderAst(post.htmlAst)}
        </section>
        <hr />
        <footer></footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/posts${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/posts${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        draft
        date(formatString: "YYYY-MM-DD HH:mm")
        textDate: date(formatString: "YYYY-MM-DD")
        description
        thumbnail {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              src
              srcSet
              sizes
              aspectRatio
            }
          }
        }
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
